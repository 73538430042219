import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAddress } from '../selectors';
import { getAddressOptions } from '../selectors/dropdowns';

import { closePopup } from '../actions/popup';
import { createAddAddress, createCopyAddress } from '../actions/address';

import { BASE_ZINDEX } from '../popup-factory';

import Select from './Select';

class AddShopAddressPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_id: '',
      address_name: '',
      address_line_1: '',
      address_line_2: '',
      address_city: '',
      address_state: '',
      address_country: '',
      address_postal: ''
    };

    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
  }

  onChangeAddress(address_id) {
    if ('ADD' === address_id) {
      this.setState({
        address_id,
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      });
    } else {
      const address = this.props.getAddress(address_id);
      this.setState({
        ...address,
        address_id
      });
    }
  }

  handleAddAddress(e) {
    e.preventDefault();
    const {
      address_id,
      address_name,
      address_line_1,
      address_line_2,
      address_city,
      address_state,
      address_country,
      address_postal,
    } = this.state;

    if (!address_id) {
      return;
    }
    if ('ADD' === address_id) {
      if (!address_name.trim() || !address_line_1.trim()) {
        return;
      }
      this.setState({
        address_id: '',
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      }, () => this.props.onAddNewAddress(
        address_name,
        address_line_1,
        address_line_2,
        address_city,
        address_state,
        address_country,
        address_postal
      ));
    } else {
      this.setState({
        address_id: '',
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      }, () => this.props.onAddClientAddress(address_id));
    }
  }

  render() {
    const { client_addresses, index, onClosePopup } = this.props;
    const { address_id, address_name, address_line_1, address_line_2, address_city, address_state, address_country, address_postal } = this.state;

    return (
      <div className="reveal tiny" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Add Address</h3>
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Done</a>
        </div>
        <div className="row small-12 columns">
          <Select options={client_addresses} value={address_id} placeholder="Select from client" change={this.onChangeAddress} />
          <input type="text" disabled={'ADD' !== address_id} value={address_name} placeholder={'ADD' === address_id ? 'Name' : ''} onChange={e => this.setState({ address_name: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_line_1} placeholder={'ADD' === address_id ? '123 Street St.' : ''} onChange={e => this.setState({ address_line_1: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_line_2} placeholder={'ADD' === address_id ? 'Suite Number' : ''} onChange={e => this.setState({ address_line_2: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_city} placeholder={'ADD' === address_id ? 'City' : ''} onChange={e => this.setState({ address_city: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_state} placeholder={'ADD' === address_id ? 'State/Province' : ''} onChange={e => this.setState({ address_state: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_postal} placeholder={'ADD' === address_id ? 'ZIP/Postal Code' : ''} onChange={e => this.setState({ address_postal: e.target.value })} />
          <input type="text" disabled={'ADD' !== address_id} value={address_country} placeholder={'ADD' === address_id ? 'Country' : ''} onChange={e => this.setState({ address_country: e.target.value })} />
          <a className="button" onClick={this.handleAddAddress}>Add</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  client_addresses: [{ key: 'ADD', value: 'Add New' }].concat(getAddressOptions(state, { parent_id: ownProps.client_id })),
  getAddress: address_id => getAddress(state, { address_id })
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onAddClientAddress: address_id => dispatch(createCopyAddress(address_id, ownProps.shop_id, 'SHOP', ownProps.address_type)),
  onAddNewAddress: (address_name, address_line_1, address_line_2, address_city, address_state, address_country, address_postal) => dispatch(createAddAddress({
    address_name,
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_country,
    address_postal,
    parent_id: ownProps.shop_id,
    parent_type: 'SHOP',
    address_type: ownProps.address_type
  }))
});

const ConnectedAddShopAddressPopup = connect(mapStateToProps, mapDispatchToProps)(AddShopAddressPopup);
export default ConnectedAddShopAddressPopup;
